const screenLayer = {
  LINK: "link",
  EXCLUSIVEDMS: "exclusivedms",
  QUOTE: "quote",
  SELECT: "select",
  SOCIAL: "social",
  SOCIALSLINK: "socialslink",
};

export default screenLayer;
